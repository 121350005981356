import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency42.png'
import gif from '../../pages/blogs/assets/latency/latency42.gif'

const Latency42 = () => (
  <FrameItem>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image
        src={gif}
        width={'75%'}
        alt={'real user monitoring'}
        marginTop={'0'}
        position={'relative'}
      />
      <Image
        src={image}
        width={'75%'}
        alt={'front end observability'}
        transform={'rotate(-0.03turn)'}
        marginTop={'0'}
        top={'auto'}
        left={'auto'}
        absolute
      />
    </div>
    <FrameParagraph>
      <u>You can integrate with a SaaS platform</u>. Personally, working at
      Datadog: I typically get to utilize their UX Monitoring services directly,
      and their tools streamline what I’m about to cover here (and in the
      following articles of this series) –– but of course it does come with a
      cost.
    </FrameParagraph>
  </FrameItem>
)

export default Latency42
