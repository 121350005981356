import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const Image = ({
  src,
  height,
  width,
  className,
  alt,
  clickEvent,
  relative,
  absolute,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  top,
  left,
  transform,
  border,
  borderRadius,
  outline,
  opacity,
  zIndex
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <img
        src={src}
        alt={alt}
        height={height}
        width={width}
        className={className}
        style={{
          border: `${border ? border : 'none'}`,
          borderRadius: `${borderRadius ? borderRadius : '12.5px'}`,
          marginTop: `${marginTop ? marginTop : '20px'}`,
          marginRight: `${marginRight}`,
          marginBottom: `${marginBottom}`,
          marginLeft: `${marginLeft}`,
          padding: `${padding}`,
          cursor: 'pointer',
          position: `${relative ? 'relative' : ''} ${
            absolute ? 'absolute' : ''
          }`,
          top: `${top}`,
          left: `${left}`,
          transform: `${transform}`,
          outline: `${outline}`,
          opacity: `${opacity}`,
          zIndex: `${zIndex}`
        }}
        onClick={clickEvent}
      />
    )}
  />
)

Image.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  transform: PropTypes.string,
  marginTop: PropTypes.string,
  marginRight: PropTypes.string,
  marginBottom: PropTypes.string,
  marginLeft: PropTypes.string,
  padding: PropTypes.string,
  alt: PropTypes.string.isRequired,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  outline: PropTypes.string,
  opacity: PropTypes.string,
  zIndex: PropTypes.string,
  clickEvent: PropTypes.func,
  relative: PropTypes.bool,
  absolute: PropTypes.bool
}

export default Image
