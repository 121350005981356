import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const GuideContainer = ({ children, position }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div
        style={{
          position: `${position}`,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '15px'
        }}
      >
        {children}
      </div>
    )}
  />
)

GuideContainer.propTypes = {
  children: PropTypes.node,
  position: PropTypes.string
}

export default GuideContainer
