import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const FrameGroup = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div
        style={{
          border: '1px solid rgba(219, 74, 140, 1)',
          borderRadius: '12.5px',
          padding: '30px 30px 0px 30px'
        }}
      >
        {children}
      </div>
    )}
  />
)

FrameGroup.propTypes = {
  children: PropTypes.node
}

export default FrameGroup
