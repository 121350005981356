import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency16.jpg'
import gif from '../../pages/blogs/assets/latency/latency16.gif'

const Latency16 = () => (
  <FrameItem>
    <Image src={image} width={'50%'} alt={'Start here'} />
    <Image src={gif} alt={'Human hand pointing in the latency pipeline'} />
    <FrameParagraph>
      If you’re going to meaningfully measure the performance of your web app, a
      good place to start is to consider the thresholds where you can accurately
      measure latencies that directly affect your users. And those thresholds
      are...
    </FrameParagraph>
  </FrameItem>
)

export default Latency16
