import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const H2 = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <h2 style={{ padding: '0', marginTop: '30px' }}>{children}</h2>
    )}
  />
)

H2.propTypes = {
  children: PropTypes.node
}

export default H2
