import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/latency45.mp4'

const Latency45 = () => (
  <FrameItem>
    <Video src={video} width={'80%'} position={'relative'} top={'30%'} />
    <FrameParagraph marginTop={'0'}>
      Observing latency can be done no matter what, so do what’s best for you
      and your team with the resources you have. To aid you in your next logical
      steps of developing this aspect of your craft, next time we{"'"}ll
      consider: what the crucial practices are for tracking the real latencies
      (that you can do something about) in your front end, the standard UX
      quality signals that simplify fostering your best experience possible, and
      how to go about setting up an observability solution that works for you
      should you choose the <u>SASS</u> or <u>FOSS</u> path.
    </FrameParagraph>
  </FrameItem>
)

export default Latency45
