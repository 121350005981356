import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency25.gif'

const Latency25 = () => (
  <FrameItem>
    <div style={{ position: 'relative' }}>
      <Image src={gif} alt={'Web app data sent to user from server'} />
      <GuideContainer>
        <p
          style={{
            color: '#0287ad',
            fontSize: '25px',
            border: '1px solid rgba(219, 74, 140, 1)',
            padding: '10px'
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            Goal: <i>~3s</i>
          </div>
          <span>
            Total:{' '}
            <span
              style={{
                textDecoration: 'underline',
                textDecorationColor: '#fda627'
              }}
            >
              2.6s
            </span>
          </span>
        </p>
      </GuideContainer>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '7%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Humans
      </div>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '18.2%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Hardware
      </div>
    </div>
    <FrameParagraph>
      Next we’ll add the 2021 average <u>time-to-first-byte metric</u>, which is
      around{' '}
      <span
        style={{ textDecoration: 'underline', textDecorationColor: '#fda627' }}
      >
        <b>2600ms</b>
      </span>
      . This includes measurements like cold starts for serverless solutions,
      and more. Now we can see that before we’ve done any front end measurements
      at all – we’ve nearly spent our latency budget, and have almost hit the
      optimal speed index for our app. That’s too bad, but we’ll move on anyway.
      Let’s talk about the <i>front end</i>.
    </FrameParagraph>
    <ImageInstruction
      margin={'0'}
      instruction={
        '(Mach Metrics: Average time to first bite on mobile in 2021)'
      }
    />
    <ImageInstruction
      margin={'0'}
      instruction={'(Cloudflare: Serverless cold start average)'}
    />
  </FrameItem>
)

export default Latency25
