import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency9.gif'

const Latency9 = () => (
  <FrameItem>
    <Image src={gif} alt={'Server response sent'} />
    <FrameParagraph>
      ... and some http handshakes that are made to set up a secure data
      pipeline.
    </FrameParagraph>
  </FrameItem>
)

export default Latency9
