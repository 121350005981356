import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const FrameItem = ({ children, margin, position }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: `${margin ? margin : '0'}`,
          boxSizing: 'border-box',
          width: '75%',
          position: `${position}`
        }}
      >
        {children}
      </div>
    )}
  />
)

FrameItem.propTypes = {
  children: PropTypes.node,
  margin: PropTypes.string,
  position: PropTypes.string
}

export default FrameItem
