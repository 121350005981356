import React from 'react'
import Video from '../../components/blog-styles/video'
import latency7 from '../../pages/blogs/assets/latency/latency7.mp4'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency7.png'

const Latency6 = () => (
  <FrameItem>
    <div style={{ position: 'relative' }}>
      <Video src={latency7} width={'80%'} top={'30%'} left={'-12%'} />
      <Image
        src={image}
        alt={
          'Tiny contractor building an applications UI on a CRT monitor with physical tools'
        }
        width={'40%'}
        borderRadius={'0'}
        absolute
        top={'8%'}
        left={'60%'}
      />
    </div>
    <FrameParagraph>
      So if measuring and mitigating latencies is this crucial to your web app’s
      performance and user experience, where do you start?
    </FrameParagraph>
  </FrameItem>
)

export default Latency6
