import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../components/blog-styles/image'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Anchor from '../../components/blog-styles/anchor'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import image from '../../pages/blogs/assets/latency/latency6.jpg'

const Latency5 = () => (
  <FrameItem>
    <Image src={image} alt={'It matters to businesses'} width={'100%'} />
    <GuideContainer>
      <p
        style={{
          fontSize: '35px',
          width: '65%',
          marginTop: '15px',
          lineHeight: '1.5'
        }}
      >
        Pinterest saw a <i>15%</i> sign-up increase by reducing the user{"'"}s
        perceived wait time by <i>-40%</i>
      </p>
      <ImageInstruction
        instruction={
          '(Source: A Pinterest Progressive Web App Performance Case Study)'
        }
        marginBottom={'30px'}
      />
    </GuideContainer>
    <FrameParagraph>
      Another important factor is that latency matters to businesses, a lot. In
      a{' '}
      <Anchor
        url={
          'https://medium.com/dev-channel/a-pinterest-progressive-web-app-performance-case-study-3bd6ed2e6154'
        }
        linkName={'brilliant Progressive Web App performance case study'}
      />{' '}
      from Pinterest, they showed that their sign-ups increased by <b>15%</b>{' '}
      when their user’s perceived wait time was reduced by <b>40%</b>.
    </FrameParagraph>
  </FrameItem>
)

Latency5.propTypes = {
  children: PropTypes.node
}

export default Latency5
