import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const Video = ({
  src,
  width,
  margin,
  border,
  borderRadius,
  position,
  top,
  left
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <video
        width={width}
        src={src}
        loop={'true'}
        autoPlay={'true'}
        style={{
          margin: `${margin}`,
          position: `${position}`,
          border: `${border}`,
          borderRadius: `${borderRadius}`,
          top: `${top}`,
          left: `${left}`
        }}
        muted
      />
    )}
  />
)

Video.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string,
  margin: PropTypes.string,
  position: PropTypes.string,
  border: PropTypes.string,
  borderRadius: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string
}

export default Video
