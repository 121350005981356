import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../components/blog-styles/image'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import latency3 from '../../pages/blogs/assets/latency/latency3.png'

const Latency2 = () => (
  <FrameItem>
    <Image
      className={'fade-in-image'}
      src={latency3}
      alt={'back to the family image'}
      width={'80%'}
      border={'3px solid #0287ad'}
    />
    <FrameParagraph marginTop={'0px'}>
      Because you’ve got to get <b>back to the family</b>, right? Addressing
      latency means more time with your family and friends, and less time
      waiting for your site to load.
    </FrameParagraph>
  </FrameItem>
)

Latency2.propTypes = {
  children: PropTypes.node
}

export default Latency2
