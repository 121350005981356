import React from 'react'
import Image from '../blog-styles/image'
import FrameItem from '../blog-styles/frame-item'
import FrameParagraph from '../blog-styles/frame-paragraph'
import image from '../../pages/blogs/assets/latency/latency39.jpg'
import image2 from '../../pages/blogs/assets/latency/latency39.png'
import image3 from '../../pages/blogs/assets/latency/latency39a.png'
import image4 from '../../pages/blogs/assets/latency/latency39b.png'
import '../layout.css'

const Latency39 = () => (
  <FrameItem>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image
        src={image}
        alt={'Performance profile of twitter.com'}
        width={'70%'}
        position={'relative'}
        marginBottom={'0'}
        padding={'0'}
      />
      <Image
        src={image3}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        marginTop={'0'}
        width={'40%'}
        className={'fade-in-image'}
        borderRadius={'0'}
      />
      <Image
        src={image2}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        marginTop={'0'}
        width={'20%'}
        className={'fade-in-image'}
        top={'82%'}
        left={'75%'}
        transform={'rotate(0.03turn)'}
        absolute
      />
      <Image
        src={image4}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        marginTop={'0'}
        width={'4%'}
        className={'fade-in-image'}
        top={'77%'}
        left={'70.7%'}
        transform={'rotate(-0.214turn)'}
        absolute
      />
    </div>
    <FrameParagraph marginTop={'10px'}>
      Yet another issue is local network congestion. If you have a{' '}
      <u>100mbps</u> internet connection at home, and a few devices are
      streaming <u>4K video</u> while you’re trying to load an app on your phone
      – you’re going to have a hard time (just like your app’s user here).
    </FrameParagraph>
  </FrameItem>
)

export default Latency39
