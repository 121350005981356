import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency19.jpg'
import gif from '../../pages/blogs/assets/latency/latency19.gif'

const Latency19 = () => (
  <FrameItem>
    <Image src={image} width={'50%'} alt={'Web Apps'} />
    <Image src={gif} alt={'Web app data sent to user from server'} />
  </FrameItem>
)

export default Latency19
