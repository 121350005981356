import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency12.png'
import gif from '../../pages/blogs/assets/latency/latency12.gif'

const Latency12 = () => (
  <FrameItem>
    <Image src={image} width={'60%'} alt={'Load and render'} />
    <Image
      src={gif}
      width={'60%'}
      alt={'script loading potatoes'}
      border={'1px solid rgba(219, 74, 140, 1)'}
      marginBottom={'0'}
    />
    <FrameParagraph>
      Then there’s the time spent loading {' & '} running the scripts, and
      rendering the DOM
    </FrameParagraph>
  </FrameItem>
)

export default Latency12
