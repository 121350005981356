import React from 'react'
import Layout from '../../components/layout'
import Nav from '../../components/nav'
import Links from '../../components/links'
import Seo from '../../components/seo'

// import copyText from 'copy-text-to-clipboard'

// Frames
import NextFrameHandler from '../../components/next-frame-handler'
import Latency1 from '../../components/frames/latency1'
import Latency2 from '../../components/frames/latency2'
import Latency3 from '../../components/frames/latency3'
import Latency4 from '../../components/frames/latency4'
import Latency5 from '../../components/frames/latency5'
import Latency6 from '../../components/frames/latency6'
import Latency7 from '../../components/frames/latency7'
import Latency8 from '../../components/frames/latency8'
import Latency9 from '../../components/frames/latency9'
import Latency10 from '../../components/frames/latency10'
import Latency11 from '../../components/frames/latency11'
import Latency12 from '../../components/frames/latency12'
import Latency13 from '../../components/frames/latency13'
import Latency14 from '../../components/frames/latency14'
import Latency15 from '../../components/frames/latency15'
import Latency16 from '../../components/frames/latency16'
import Latency17 from '../../components/frames/latency17'
import Latency18 from '../../components/frames/latency18'
import Latency19 from '../../components/frames/latency19'
import Latency20 from '../../components/frames/latency20'
import Latency21 from '../../components/frames/latency21'
import Latency22 from '../../components/frames/latency22'
import Latency23 from '../../components/frames/latency23'
import Latency24 from '../../components/frames/latency24'
import Latency25 from '../../components/frames/latency25'
import Latency26 from '../../components/frames/latency26'
import Latency27 from '../../components/frames/latency27'
import Latency28 from '../../components/frames/latency28'
import Latency29 from '../../components/frames/latency29'
import Latency30 from '../../components/frames/latency30'
import Latency31 from '../../components/frames/latency31'
import Latency32 from '../../components/frames/latency32'
import Latency33 from '../../components/frames/latency33'
import Latency34 from '../../components/frames/latency34'
import Latency35 from '../../components/frames/latency35'
import Latency36 from '../../components/frames/latency36'
import Latency37 from '../../components/frames/latency37'
import Latency38 from '../../components/frames/latency38'
import Latency39 from '../../components/frames/latency39'
import Latency40 from '../../components/frames/latency40'
import Latency41 from '../../components/frames/latency41'
import Latency42 from '../../components/frames/latency42'
import Latency43 from '../../components/frames/latency43'
import Latency44 from '../../components/frames/latency44'
import Latency45 from '../../components/frames/latency45'
import Latency46 from '../../components/frames/latency46'

// Assets
import title from './assets/latency/latency1.mp4'

// Style Components
import A from '../../components/blog-styles/anchor'
import Container from '../../components/blog-styles/container'
import H2 from '../../components/blog-styles/h2'
import Note from '../../components/blog-styles/note'
import TitleQuote from '../../components/blog-styles/title-quote'
import Video from '../../components/blog-styles/video'
import TimeStamp from '../../components/blog-styles/time-stamp'

const FrameArray = [
  Latency1(),
  Latency2(),
  Latency3(),
  Latency4(),
  Latency5(),
  Latency6(),
  Latency7(),
  Latency8(),
  Latency9(),
  Latency10(),
  Latency11(),
  Latency12(),
  Latency13(),
  Latency14(),
  Latency15(),
  Latency16(),
  Latency17(),
  Latency18(),
  Latency19(),
  Latency20(),
  Latency21(),
  Latency22(),
  Latency23(),
  Latency24(),
  Latency25(),
  Latency26(),
  Latency27(),
  Latency28(),
  Latency29(),
  Latency30(),
  Latency31(),
  Latency32(),
  Latency33(),
  Latency34(),
  Latency35(),
  Latency36(),
  Latency37(),
  Latency38(),
  Latency39(),
  Latency40(),
  Latency41(),
  Latency42(),
  Latency43(),
  Latency44(),
  Latency45(),
  Latency46()
]

const LatencyWhatDevelopersCanControlOnTheFrontEndWithJavaScript = () => (
  <Layout>
    <Seo title="HTTPS for local developement" />
    <Container>
      <Video src={title} width={'85%'} />
      <TitleQuote
        margin={'40px 0 65px 0'}
        quote={
          'The next big thing is the one that makes the last big thing usable.'
        }
        author={'Blake Ross'}
      />
      <p>
        <i>What you{"'"}re about to take part in</i> is the result of my
        two-year journey towards making sense of what latency actually is in the
        context of a web application, with careful consideration to each vector
        that contributes to delays on the front end that surface while profiling
        an application, and also what a front end developer both does and does
        not have control over when addressing every known factor of latency.
      </p>
      <p>
        Much of what follows here has been adapted from a live talk that I gave
        a handful of times throughout 2022 (most notably at{' '}
        <A
          url={
            'https://2022.allthingsopen.org/sessions/latency-what-developers-can-control-on-the-front-end/'
          }
          linkName={'All Things Open'}
        />
        ).
      </p>
      <p>
        Herein there’s no seasoned experience required of you, the reader, in
        order to have a meaningful engagement with this multipart series of
        graphic articles – however a general familiarity with{' '}
        <u>web app development</u>,{' '}
        <u>
          <A
            url="https://developer.chrome.com/docs/devtools/performance/"
            linkName={'front end profiling'}
          />
        </u>
        ,{' '}
        <u>
          <A
            url="https://en.wikipedia.org/wiki/Observability_(software)"
            linkName={'Observability'}
          />
        </u>
        , and{' '}
        <u>
          <A
            url={'https://developer.mozilla.org/en-US/docs/Web/JavaScript'}
            linkName={'JavaScript'}
          />
        </u>{' '}
        will make your forthcoming engagement more comprehensible and enjoyable.
      </p>
      <p>Let{"'"}s begin.</p>

      <H2>
        <b style={{ color: '#373586' }}>Part one</b>:{' '}
        <span style={{ color: '#027ca6' }}>redefining latency{"'"}s scope</span>
      </H2>
      <Note>
        Please use the arrow keys, or swipe left and right to advance each frame
        in the view below:
      </Note>
      <NextFrameHandler components={FrameArray} />
      <TimeStamp marginTop={'50px'} date={'September 28th, 2023'} />
      <Nav />
      <Links />
    </Container>
  </Layout>
)

export default LatencyWhatDevelopersCanControlOnTheFrontEndWithJavaScript
