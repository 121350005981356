import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const Note = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <p
          style={{
            color: 'rgb(116, 116, 116)',
            fontStyle: 'italic',
            fontWeight: '300',
            marginBottom: '30px'
          }}
        >
          <b>Note</b>: {children}
        </p>
      </>
    )}
  />
)

Note.propTypes = {
  children: PropTypes.node
}

export default Note
