import React from 'react'
import Anchor from '../../components/blog-styles/anchor'
import FrameItem from '../../components/blog-styles/frame-item'
import Video from '../../components/blog-styles/video'
import Image from '../../components/blog-styles/image'
import video from '../../pages/blogs/assets/latency/latency13.mp4'
import image from '../../pages/blogs/assets/latency/latency13.png'

const Latency13 = () => (
  <FrameItem>
    <Video src={video} width={'48%'} position={'relative'} />
    <Image src={image} width={'20%'} alt={'script loading potatoes'} absolute />
    <p
      style={{
        position: 'absolute',
        top: '60vh',
        transform: 'rotate(0.02turn)',
        border: '1px solid #de4990',
        borderRadius: '12.5px',
        margin: '0 40px',
        padding: '5px',
        background: '#fff'
      }}
    >
      And finally everything gets painted on the screen … and therefore all the
      latencies that matter regarding your app’s front end perf have been
      accounted for, and can now be measured using a browser dev tool like{' '}
      <Anchor
        url={'https://github.com/GoogleChrome/lighthouse/blob/main/readme.md'}
        linkName={'Lighthouse'}
      />
      , and alleviated – <b>right?</b>
    </p>
  </FrameItem>
)

export default Latency13
