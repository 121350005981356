import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency14.gif'

const Latency14 = () => (
  <FrameItem>
    <Image
      src={gif}
      width={'75%'}
      alt={'script loading potatoes'}
      border={'1px solid rgba(219, 74, 140, 1)'}
    />
    <FrameParagraph>
      <div
        style={{
          transform: 'rotate(-0.02turn)'
        }}
      >
        Well,{' '}
        <b>
          <i>
            <u>no</u>
          </i>
          .
        </b>
      </div>
    </FrameParagraph>
  </FrameItem>
)

export default Latency14
