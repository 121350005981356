import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency28.gif'

const Latency28 = () => (
  <FrameItem>
    <div style={{ position: 'relative' }}>
      <Image src={gif} alt={'Web app data sent to user from server'} />
      <GuideContainer>
        <p
          style={{
            color: '#0287ad',
            fontSize: '25px',
            border: '1px solid rgba(219, 74, 140, 1)',
            padding: '10px'
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            Goal: <i>~3s</i>
          </div>
          <span>
            Total:{' '}
            <span
              style={{
                textDecoration: 'underline',
                textDecorationColor: '#ff0000'
              }}
            >
              12.2s
            </span>
          </span>
        </p>
      </GuideContainer>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '6%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Humans
      </div>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '17%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Hardware
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '46.5%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Web Apps
      </div>
    </div>
    <FrameParagraph>
      Then the browser is going to parse the incoming scripts (and put any
      rendering on hold since JavaScript can’t do both on the main thread at
      once – being a single threaded runtime). This will add an additional{' '}
      <b>8300ms</b>, bringing our speed index up to{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#fda627'
        }}
      >
        <b>12.2</b>
      </span>{' '}
      seconds.
    </FrameParagraph>
  </FrameItem>
)

export default Latency28
