import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency32.gif'

const Latency32 = () => (
  <FrameItem>
    <Image
      src={gif}
      marginTop={'0'}
      marginBottom={'0'}
      alt={'+215ms for mental chronometry'}
    />
    <FrameParagraph marginTop={'0'}>
      It’s again worth noting that there{"'"}s another factor we can consider in
      this performance calculation: <u>mental chronometry</u>. If the app’s
      first paints include some information about loading, and then the initial
      DOM content gives the user something to do before the app fully loads – we
      can slightly decrease the overall perceived wait time here. But this can’t
      resolve the fact that this is taking too long to load, render, and paint
      the content right? Shouldn’t the goal for the total acceptable latency of
      say, a mobile SPA, be closer to that{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#02fe00'
        }}
      >
        <b>3s</b>
      </span>{' '}
      range rather than around{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#fda627'
        }}
      >
        <b>15 seconds</b>
      </span>
      ?
    </FrameParagraph>
  </FrameItem>
)

export default Latency32
