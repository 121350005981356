import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const FrameContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          alignItems: 'center',
          margin: '0px',
          minHeight: '90vh',
          width: '90vw',
          maxWidth: '100%',
          maxHeight: '100%',
          border: '1px solid rgba(19, 129, 171, 1)',
          borderRadius: '12.5px',
          boxSizing: 'border-box',
          boxShadow: '0.4em 0.4em 0px 0.1em rgba(19, 129, 171, 0.3)'
        }}
      >
        {children}
      </div>
    )}
  />
)

FrameContainer.propTypes = {
  children: PropTypes.node
}

export default FrameContainer
