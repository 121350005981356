import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../components/frames/frame-styles.css'
import FrameContainer from '../components/frames/frame-container'

class KeyPressHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
      startX: null
    }
  }

  handleKeyPress = (event) => {
    const { currentIndex } = this.state
    const { components } = this.props

    if (event.key === 'ArrowRight' && currentIndex < components.length - 1) {
      this.setState({ currentIndex: currentIndex + 1 })
    } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
      this.setState({ currentIndex: currentIndex - 1 })
    }
  }

  handleTouchStart = (e) => {
    this.setState({ startX: e.touches[0].clientX })
  }

  handleTouchMove = (e) => {
    if (!this.state.startX) return

    const currentX = e.touches[0].clientX
    const deltaX = currentX - this.state.startX

    console.log(deltaX, this.state)

    if (
      deltaX < 0 &&
      this.state.currentIndex < this.props.components.length - 1
    ) {
      // Swipe right
      console.log('you are right', deltaX)
      this.handleSwipeRight()
    } else if (deltaX > 0 && this.state.currentIndex > 0) {
      // Swipe left
      console.log('you are left', deltaX)
      this.handleSwipeLeft()
    }

    this.setState({ startX: null })
  }

  handleSwipeRight = () => {
    // Handle swipe right
    console.log('swipe right')
    this.setState({ currentIndex: this.state.currentIndex + 1 })
  }

  handleSwipeLeft = () => {
    // Handle swipe left
    console.log('swipe left')
    this.setState({ currentIndex: this.state.currentIndex - 1 })
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress)
  }

  render() {
    const { currentIndex } = this.state
    const { components } = this.props

    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={() => this.setState({ startX: null })}
      >
        <FrameContainer>
          {components[currentIndex]}
          <div
            style={{
              color: 'rgba(19, 129, 171, 1)',
              position: 'absolute',
              top: '100%',
              paddingTop: '15px'
            }}
          >
            {currentIndex + 1 === components.length
              ? 'END OF'
              : currentIndex + 1}
            <span style={{ color: '#363883' }}>
              {' / '}
              {currentIndex + 1 === components.length
                ? 'PART ONE'
                : components.length}
            </span>
          </div>
        </FrameContainer>
      </div>
    )
  }
}

KeyPressHandler.propTypes = {
  components: PropTypes.array.isRequired
}

export default KeyPressHandler
