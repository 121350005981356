import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import Image from '../../components/blog-styles/image'
import image2 from '../../pages/blogs/assets/latency/latency26.jpg'
import image3 from '../../pages/blogs/assets/latency/latency26a.jpg'

const Latency26 = () => (
  <FrameItem>
    <Image src={image2} width={'33%'} alt={'Load and render'} />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        color: '#0287ad',
        fontSize: '30px'
      }}
    >
      <span style={{ textTransform: 'none', marginBottom: '20px' }}>
        Average page load time on mobile:
      </span>
    </div>
    <Image
      src={image3}
      width={'55%'}
      marginTop={'0'}
      border={'3px solid #0287ad'}
    />
    <ImageInstruction
      instruction={'(Google: average mobile page load time)'}
      marginBottom={'10px'}
    />
    <FrameParagraph marginTop={'10px'}>
      According to Google research,{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#fda627'
        }}
      >
        <b>15.3 seconds</b>
      </span>{' '}
      is the average time it takes to fully load a mobile page. So as we
      continue to fall far beyond the ideal goal of around{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#02fe00'
        }}
      >
        <b>3 seconds</b>
      </span>
      , we’re now going to see how our app stacks up against the average global
      load time.
    </FrameParagraph>
  </FrameItem>
)

export default Latency26
