import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency17.jpg'
import gif from '../../pages/blogs/assets/latency/latency17.gif'

const Latency17 = () => (
  <FrameItem>
    <Image src={image} width={'50%'} alt={'Humans'} />
    <Image src={gif} alt={'Human hand pointing in the latency pipeline'} />
  </FrameItem>
)

export default Latency17
