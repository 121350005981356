import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency8.png'
import gif from '../../pages/blogs/assets/latency/latency8.gif'

const Latency7 = () => (
  <FrameItem>
    <Image src={image} alt={'My initial assumptions'} />
    <Image
      src={gif}
      alt={
        'Then there’s the time spent loading & running the scripts, and rendering the DOM'
      }
    />
    <FrameParagraph>
      Well, while considering what to measure on the front end, your initial
      assumptions might be <u>something like this</u> ...
    </FrameParagraph>
  </FrameItem>
)

export default Latency7
