import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const A = ({ children, url, linkName }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <a
          href={url}
          style={{ padding: '0', fontWeight: '400', color: '#47659c' }}
        >
          {linkName}
        </a>
        {children}
      </>
    )}
  />
)

A.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired
}

export default A
