import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency44.png'

const Latency44 = () => (
  <FrameItem>
    <Image
      className={'fade-in-image2'}
      src={image}
      width={'40%'}
      marginBottom={'0'}
      alt={'front end observability'}
    />
    <FrameParagraph marginTop={'0'}>
      But we’d like to understand what all of our options are – not just the
      ones that lead us to signing up for more proprietary services. No, we’d
      also like to understand how to utilize tools that help us stay in the
      open, while also supporting the free software ecosystem wherever we can.
      As we approach the next steps in this journey of addressing front end
      latency, we can rest on a more fundamental frame:
    </FrameParagraph>
  </FrameItem>
)

export default Latency44
