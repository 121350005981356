import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency37.png'
import image2 from '../../pages/blogs/assets/latency/latency37a.png'
import image3 from '../../pages/blogs/assets/latency/latency37b.png'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/latency37.mp4'

const Latency37 = () => (
  <FrameItem>
    <Video
      src={video}
      margin={'15px 0 0 0'}
      width={'45%'}
      position={'relative'}
      top={'30%'}
    />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        color: '#0287ad',
        fontSize: '18px',
        width: '100%',
        margin: '20px 20px 5px 20px'
      }}
    >
      <Image
        src={image2}
        marginTop={'0px'}
        marginRight={'7px'}
        width={'10%'}
        alt={'120 miles'}
      />
      <span style={{ textTransform: 'none' }}>adds</span>
      <Image
        src={image3}
        marginTop={'0px'}
        marginLeft={'7px'}
        marginRight={'7px'}
        width={'8%'}
        alt={'around 1ms'}
      />
      <span style={{ textTransform: 'none' }}>
        of latency to the average packet (500b)
      </span>
    </div>
    <Image
      src={image}
      width={'70%'}
      marginTop={'0'}
      marginBottom={'10px'}
      border={'3px solid #0287ad'}
    />
    <ImageInstruction
      instruction={
        '(Noction: Network latency and its effect on application performance)'
      }
      marginBottom={'10px'}
    />
    <FrameParagraph marginTop={'10px'}>
      The most significant one is distance. The math works such that{' '}
      <u>120 miles</u> adds around <b>1ms</b> of latency to the average packet (
      <b>500b</b>). It’s a huge culprit in adding latency to the overall
      experience.
    </FrameParagraph>
  </FrameItem>
)

export default Latency37
