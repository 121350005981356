import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency33.gif'

const Latency33 = () => (
  <FrameItem>
    <Image
      src={gif}
      width={'55%'}
      marginTop={'0'}
      marginBottom={'0'}
      alt={'+215ms for mental chronometry'}
    />
    <FrameParagraph marginTop={'0'}>
      Something seems broken about this process, doesn’t it?
    </FrameParagraph>
  </FrameItem>
)

export default Latency33
