import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import InlineCode from '../blog-styles/inline-code'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency43.png'
import image2 from '../../pages/blogs/assets/latency/latency43.jpeg'
import gif from '../../pages/blogs/assets/latency/latency43.gif'

const Latency43 = () => (
  <FrameItem>
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image
        src={gif}
        width={'80%'}
        alt={'real user monitoring'}
        marginTop={'0'}
        position={'relative'}
      />
      <Image
        src={image}
        width={'35%'}
        className={'fade-in-image'}
        border={'solid 5px #0287ad'}
        borderRadius={'10%'}
        alt={'front end observability'}
        transform={'rotate(-0.03turn)'}
        top={'7%'}
        left={'21%'}
        zIndex={'1'}
        absolute
      />
      <Image
        src={image2}
        className={'fade-in-image4'}
        width={'25%'}
        border={'solid 3px #d54183'}
        borderRadius={'45%'}
        alt={'front end observability'}
        transform={'rotate(0.03turn)'}
        top={'2%'}
        left={'52%'}
        opacity={'0.8'}
        absolute
      />
    </div>
    <FrameParagraph>
      <b>For example</b>: all you have to do is add a blob of JS to your app’s{' '}
      <InlineCode>index.js</InlineCode> file and <i>wowie zowie man</i> – the
      session data for all your users starts to stream in, and you can
      immediately generate averages and alerts from that to determine which
      vectors of your front end are healthy, or not.
    </FrameParagraph>
  </FrameItem>
)

export default Latency43
