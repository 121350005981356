import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../components/blog-styles/image'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import FrameGroup from '../../components/blog-styles/frame-group'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import image from '../../pages/blogs/assets/latency/latency4.jpg'

const Latency3 = () => (
  <FrameItem>
    <Image src={image} alt={'It matters to people the most'} width={'100%'} />
    <FrameGroup>
      <GuideContainer>
        <p style={{ fontSize: '33px' }}>
          The speed it takes to load a page: <i>75%</i>
        </p>
        <p style={{ fontSize: '26px' }}>
          How easy it is to find what I{"'"}m looking for: <i>66%</i>
        </p>
        <p style={{ fontSize: '23px' }}>
          How well the site fits my screen: <i>61%</i>
        </p>
        <p style={{ fontSize: '20px' }}>
          How simple the site is to use: <i>58%</i>
        </p>
        <p style={{ fontSize: '16px' }}>
          How attractive the site looks: <i>24%</i>
        </p>
        <ImageInstruction
          instruction={'(Paul Irish, Elizabeth Sweeney: Google I/O 2019)'}
          marginBottom={'30px'}
        />
      </GuideContainer>
    </FrameGroup>
    <FrameParagraph>
      And most people feel this way about web apps too. In a more recent mobile
      performance survey used by Google, we can see that the speed it takes to
      load a page significantly outranks any other major concern that people
      have when using a web app on their phone.
    </FrameParagraph>
  </FrameItem>
)

Latency3.propTypes = {
  children: PropTypes.node
}

export default Latency3
