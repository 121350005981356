import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency24.gif'

const Latency24 = () => (
  <FrameItem>
    <div style={{ position: 'relative' }}>
      <Image src={gif} alt={'Web app data sent to user from server'} />
      <GuideContainer>
        <p
          style={{
            color: '#0287ad',
            fontSize: '25px',
            border: '1px solid rgba(219, 74, 140, 1)',
            padding: '10px'
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            Goal: <i>~3s</i>
          </div>
          <span>
            Total:{' '}
            <span
              style={{
                textDecoration: 'underline',
                textDecorationColor: '#02fe00'
              }}
            >
              50ms
            </span>
          </span>
        </p>
      </GuideContainer>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '7%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Humans
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '19%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Hardware
      </div>
    </div>
    <FrameParagraph>
      OK so with that, we’ll go ahead and start tracking the latency of our web
      app’s mobile experience, and add{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#02fe00'
        }}
      >
        <b>50ms</b>
      </span>{' '}
      to the total latency we’ve incurred – just by simply using common
      hardware.
    </FrameParagraph>
  </FrameItem>
)

export default Latency24
