import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/latency15.mp4'

const Latency15 = () => (
  <FrameItem>
    <Video src={video} width={'80%'} position={'relative'} top={'30%'} />
  </FrameItem>
)

export default Latency15
