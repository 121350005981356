import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency41.png'
import gif from '../../pages/blogs/assets/latency/latency41.gif'

const Latency41 = () => (
  <FrameItem>
    <Image src={image} width={'75%'} alt={'front end observability'} />
    <Image
      src={gif}
      width={'40%'}
      alt={'real user monitoring'}
      marginTop={'0'}
    />
    <FrameParagraph>
      Configuration is for another article, but what I hope to leave you with
      today is an inkling of where you{"'"}re able to get started with securing
      the future quality of your web app’s UX performance right now, given this
      redifinition of scope. I hope that regardless of which implementation path
      you choose to pursue – you can get a solid UX Monitoring solution that
      works for you and your front end team, into your toolbelts. So with that,
      we{"'"}ll land on a couple well worn paths to consider as you move
      forward:
    </FrameParagraph>
  </FrameItem>
)

export default Latency41
