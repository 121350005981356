import React from 'react'
import Video from '../blog-styles/video'
import Image from '../blog-styles/image'
import FrameItem from '../blog-styles/frame-item'
import ImageInstruction from '../blog-styles/image-instruction'
import FrameParagraph from '../blog-styles/frame-paragraph'
import latency21 from '../../pages/blogs/assets/latency/latency21.mp4'
import image from '../../pages/blogs/assets/latency/latency21.png'
import image2 from '../../pages/blogs/assets/latency/latency21a.png'
import image3 from '../../pages/blogs/assets/latency/latency21b.png'
import '../layout.css'

const Latency21 = () => (
  <FrameItem>
    <Video src={latency21} margin={'20px 0 0 0'} width={'100%'} />
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Image
        src={image}
        alt={'Performance profile of twitter.com'}
        width={'45%'}
        position={'relative'}
      />
      <Image
        src={image2}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        width={'80%'}
        marginTop={'12%'}
        className={'fade-in-image'}
        absolute
      />
      <Image
        src={image3}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        width={'5%'}
        marginTop={'-13%'}
        className={'fade-in-image'}
        transform={'rotate(0.10turn)'}
        left={'26%'}
        absolute
      />
      <Image
        src={image3}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        width={'5%'}
        marginTop={'-13%'}
        className={'fade-in-image2'}
        transform={'rotate(0.10turn)'}
        left={'40%'}
        absolute
      />
      <Image
        src={image3}
        alt={
          'Circle over the delay between the initial loading and load completion on the front end'
        }
        width={'5%'}
        marginTop={'-13%'}
        className={'fade-in-image3'}
        transform={'rotate(0.10turn)'}
        left={'54%'}
        absolute
      />
    </div>
    <ImageInstruction
      instruction={'(Chromium Dev Tools profiling twitter.com)'}
      marginBottom={'10px'}
    />
    <FrameParagraph marginTop={'10px'}>
      This mental chronometry helps to reduce the user’s perceived wait time of
      a single page web app, because the user gets to start making decisions
      about what to do after the first few paints, even if all the content
      hasn’t been loaded yet. For example here, in twitter.com (RIP) you can see
      that as soon as there are contentful paints, the user can reason about
      what they’re looking at – first with a splash screen for loading, and then
      with some navigation content and news, even before tweets from the
      profiles the user follows are loaded. And{' '}
      <u>
        all of this happens long before the full onload event occurs in the
        browser
      </u>
      .
    </FrameParagraph>
  </FrameItem>
)

export default Latency21
