import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import GuideContainer from '../../components/blog-styles/guide-container'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency22.gif'

const Latency22 = () => (
  <FrameItem>
    <div style={{ position: 'relative' }}>
      <Image src={gif} alt={'Web app data sent to user from server'} />
      <GuideContainer>
        <p
          style={{
            color: '#0287ad',
            fontSize: '25px',
            border: '1px solid rgba(219, 74, 140, 1)',
            padding: '10px'
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            Goal: <i>~3s</i>
          </div>
          <span>Total:</span>
        </p>
        <ImageInstruction
          instruction={`(Google${"'"}s optimal mobile performance benchmark)`}
          marginBottom={'10px'}
        />
      </GuideContainer>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '4%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Humans
      </div>
    </div>
    <FrameParagraph>
      Our initial loading goal will be <b>3s</b> in order to keep our users from
      bouncing –– but we can’t jump into software just yet, we first need to
      consider the latencies that are inherent to the hardware environment our
      apps run in.
    </FrameParagraph>
  </FrameItem>
)

export default Latency22
