import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const FrameParagraph = ({
  children,
  absolute,
  top,
  left,
  marginTop,
  padding
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <p
        style={{
          position: `${absolute ? 'absolute' : ''}`,
          marginTop: `${marginTop ? marginTop : '50px'}`,
          padding: `${padding}`,
          top: `${top}`,
          left: `${left}`
        }}
      >
        {children}
      </p>
    )}
  />
)

FrameParagraph.propTypes = {
  children: PropTypes.node,
  top: PropTypes.string,
  left: PropTypes.string,
  marginTop: PropTypes.string,
  padding: PropTypes.string,
  absolute: PropTypes.bool
}

export default FrameParagraph
