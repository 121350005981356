import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/latency34.mp4'

const Latency34 = () => (
  <FrameItem>
    <Video src={video} width={'80%'} position={'relative'} top={'30%'} />
    <FrameParagraph marginTop={'0'}>
      Anyway, we’re done! At least for now, right?
    </FrameParagraph>
  </FrameItem>
)

export default Latency34
