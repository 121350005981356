import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../components/blog-styles/image'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameGroup from '../../components/blog-styles/frame-group'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import image from '../../pages/blogs/assets/latency/latency5.jpg'

const Latency4 = () => (
  <FrameItem>
    <Image
      src={image}
      alt={'As the page load time goes from...'}
      width={'100%'}
    />
    <FrameGroup>
      <GuideContainer>
        <p style={{ fontSize: '25px' }}>
          <u>1s to 3s</u>: the probability of bounce increases <i>32%</i>
        </p>
        <p style={{ fontSize: '25px' }}>
          <u>3s to 5s</u>: the probability of bounce increases <i>90%</i>
        </p>
        <p
          style={{
            fontSize: '25px',
            padding: '5px',
            border: '1px solid rgba(19, 129, 171, 1)'
          }}
        >
          <u>5s to 6s</u>: the probability of bounce increases <i>106%</i>
        </p>
        <p style={{ fontSize: '25px' }}>
          <u>6s to 10s</u>: the probability of bounce increases <i>123%</i>
        </p>
        <ImageInstruction
          instruction={'(Paul Irish, Elizabeth Sweeney: Google I/O 2019)'}
          marginBottom={'30px'}
        />
      </GuideContainer>
    </FrameGroup>
    <FrameParagraph>
      It’s also clear that the probability of someone bouncing from a web app
      (and likely never looking back) is <b>over 100%</b>, if it took up to{' '}
      <b>6 seconds</b> to load.
    </FrameParagraph>
  </FrameItem>
)

Latency4.propTypes = {
  children: PropTypes.node
}

export default Latency4
