import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency40.png'
import image2 from '../../pages/blogs/assets/latency/latency40.jpg'

const Latency40 = () => (
  <FrameItem>
    <Image src={image2} width={'70%'} alt={'front end observability'} />
    <Image
      src={image}
      width={'70%'}
      alt={'real user monitoring'}
      marginTop={'0'}
      padding={'2px 0 0 3px'}
    />
    <FrameParagraph marginTop={'10px'}>
      So now that we have a more accurate scope regarding all of the elements
      that have to perform well in order to deliver a good user experience,
      let’s begin to think on putting some useful front end monitoring into
      action so we can accurately measure the performance issues that we can do
      something about.
    </FrameParagraph>
  </FrameItem>
)

export default Latency40
