import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const InlineCode = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <span
        style={{
          color: 'rgb(0, 0, 0)',
          marginRight: '2px',
          padding: '4px',
          background: 'rgb(239, 241, 242)',
          bordeRradius: '3px'
        }}
      >
        {children}
      </span>
    )}
  />
)

InlineCode.propTypes = {
  children: PropTypes.node
}

export default InlineCode
