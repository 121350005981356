import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import ImageInstruction from '../../components/blog-styles/image-instruction'
import image from '../../pages/blogs/assets/latency/latency18.jpg'
import gif from '../../pages/blogs/assets/latency/latency23.gif'

const Latency23 = () => (
  <FrameItem>
    <Image src={image} width={'38%'} alt={'Load and render'} />
    <p
      style={{
        color: '#0287ad',
        fontSize: '30px',
        padding: '0'
      }}
    >
      There{"'"}s less of a latency budget than you think.
    </p>
    <Image
      src={gif}
      width={'60%'}
      alt={'script loading potatoes'}
      border={'2px solid rgba(219, 74, 140, 1)'}
    />
    <ImageInstruction
      instruction={'(Microsoft Research: Applied Sciences Group, 2012)'}
      marginBottom={'10px'}
    />
    <FrameParagraph marginTop={'7px'}>
      In light of this video from Microsoft Research, we can see that the
      average latency in their test range for mobile devices is <b>50ms</b>.
      What this immediately shows us is that there’s already{' '}
      <u>less of a latency budget than what{"'"}s usually considered</u> when
      profiling a front end using the baseline dev tools.
    </FrameParagraph>
  </FrameItem>
)

export default Latency23
