import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import Image from '../../components/blog-styles/image'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/latency36.mp4'
import image from '../../pages/blogs/assets/Latency/latency36.png'

const Latency36 = () => (
  <FrameItem>
    <Video src={video} width={'70%'} position={'relative'} top={'30%'} />
    <div
      style={{
        marginTop: '20px',
        color: '#0287ad',
        fontSize: '25px'
      }}
    >
      <span style={{ marginTop: '20px', textTransform: 'none' }}>
        Other relevant latencies are
      </span>
    </div>
    <Image
      src={image}
      width={'35%'}
      marginTop={'14px'}
      padding={'2px 0 0 3px'}
    />
  </FrameItem>
)

export default Latency36
