import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency10.gif'

const Latency10 = () => (
  <FrameItem>
    <Image src={gif} alt={'Server response sent'} />
    <FrameParagraph>
      Then there’s the time it takes to deliver a bundle, session data, assets,
      and more.
    </FrameParagraph>
  </FrameItem>
)

export default Latency10
