import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/Latency/latency38.gif'
import image from '../../pages/blogs/assets/Latency/latency38.jpg'
import image2 from '../../pages/blogs/assets/Latency/latency38a.png'
import image3 from '../../pages/blogs/assets/Latency/latency38b.png'
import image4 from '../../pages/blogs/assets/Latency/latency38c.png'
import image5 from '../../pages/blogs/assets/Latency/latency38d.png'

const Latency38 = () => (
  <FrameItem>
    <Image
      src={image}
      width={'50%'}
      marginTop={'14px'}
      padding={'2px 0 0 3px'}
    />
    <div style={{ position: 'relative' }}>
      <Image
        src={gif}
        width={'95%'}
        marginLeft={'4%'}
        alt={'global network congestion'}
      />
      <Image
        className={'fade-in-image3'}
        src={image2}
        width={'12%'}
        top={'10.7%'}
        left={'8.5%'}
        borderRadius={'0'}
        alt={'traffic'}
        absolute
      />
      <Image
        className={'fade-in-image3'}
        src={image3}
        width={'20%'}
        top={'6.5%'}
        left={'41%'}
        borderRadius={'0'}
        alt={'traffic'}
        absolute
      />
      <Image
        className={'fade-in-image3'}
        src={image4}
        width={'13%'}
        top={'17%'}
        left={'65%'}
        borderRadius={'0'}
        alt={'traffic'}
        absolute
      />
      <Image
        className={'fade-in-image3'}
        src={image5}
        width={'9%'}
        top={'22.5%'}
        left={'81.5%'}
        borderRadius={'0'}
        alt={'traffic'}
        absolute
      />
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '4%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Europe
      </div>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '28%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Africa
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-4%',
          left: '65%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        North_America
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '46.5%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        LATAM
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '81%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Middle_East
      </div>
    </div>
    <FrameParagraph marginTop={'20px'}>
      Another is regional network congestion. Not all regions have the same
      amount of bandwidth allocated to them, and when their traffic gets overly
      congested, your data will get stuck in it –– and be late.
    </FrameParagraph>
  </FrameItem>
)

export default Latency38
