import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Anchor from '../blog-styles/anchor'

const ImageInstruction = ({
  children,
  instruction,
  padding,
  marginBottom,
  margin,
  linkURL,
  top,
  absolute
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <i
          style={{
            animation: 'none',
            color: '#747474',
            fontWeight: '300',
            textTransform: 'none',
            fontSize: '14px',
            marginBottom: `${marginBottom ? marginBottom : '50px'}`,
            margin: `${margin}`,
            padding: `${padding}`,
            position: `${absolute ? 'absolute' : ''}`,
            top: `${top}`
          }}
        >
          {linkURL ? (
            <Anchor url={linkURL} linkName={instruction} />
          ) : (
            instruction
          )}
        </i>
        {children}
      </>
    )}
  />
)

ImageInstruction.propTypes = {
  children: PropTypes.node,
  instruction: PropTypes.string.isRequired,
  linkURL: PropTypes.string,
  top: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  absolute: PropTypes.bool,
  marginBottom: PropTypes.string
}

export default ImageInstruction
