import React from 'react'
import Video from '../../components/blog-styles/video'
import latency2 from '../../pages/blogs/assets/latency/latency2.mp4'
import FrameItem from '../../components/blog-styles/frame-item'
const Latency1 = () => (
  <FrameItem>
    <Video src={latency2} width={'90%'} />
  </FrameItem>
)

export default Latency1
