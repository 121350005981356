import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const TitleQuote = ({ children, quote, author, margin }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <i
          style={{
            margin: `${margin ? margin : '40px 0 80px 0'}`,
            animation: 'none',
            color: '#747474',
            fontSize: '20px'
          }}
        >
          {'"'}
          {quote}
          {'" –'}
          {author}
        </i>
        {children}
      </>
    )}
  />
)

TitleQuote.propTypes = {
  children: PropTypes.node,
  quote: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  margin: PropTypes.string
}

export default TitleQuote
