import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import Image from '../../components/blog-styles/image'
import image from '../../pages/blogs/assets/latency/latency18.jpg'
import gif from '../../pages/blogs/assets/latency/latency18.gif'

const Latency18 = () => (
  <FrameItem>
    <Image src={image} width={'50%'} alt={'Hardware'} />
    <Image
      src={gif}
      alt={'Phone representing hardware added to the latency pipeline'}
    />
  </FrameItem>
)

export default Latency18
