import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Video from '../../components/blog-styles/video'
import video from '../../pages/blogs/assets/Latency/thankyou.mp4'

const Latency46 = () => (
  <FrameItem>
    <Video
      src={video}
      width={'80%'}
      borderRadius={'40px'}
      top={'50%'}
      absolute
    />
    <FrameParagraph marginTop={'0'}>
      <span
        style={{
          color: '#0087ad',
          textDecoration: 'underline',
          textDecorationColor: '#02fe00'
        }}
      >
        <b>See you then!</b>
      </span>
    </FrameParagraph>
  </FrameItem>
)

export default Latency46
