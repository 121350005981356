import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency8a.gif'

const Latency8 = () => (
  <FrameItem>
    <Image src={gif} alt={'User request sent'} />
    <FrameParagraph>
      The user sends a request, and there’s an initial server boot up time ...
    </FrameParagraph>
  </FrameItem>
)

export default Latency8
