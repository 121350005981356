import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import GuideContainer from '../../components/blog-styles/guide-container'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency31.gif'

const Latency31 = () => (
  <FrameItem>
    <div style={{ position: 'relative', marginTop: '-30px' }}>
      <Image src={gif} alt={'Web app data sent to user from server'} />
      <GuideContainer>
        <p
          style={{
            color: '#0287ad',
            fontSize: '25px',
            border: '1px solid rgba(219, 74, 140, 1)',
            margin: '-35px 0 0 0',
            padding: '10px'
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            Goal: <i>~3s</i>
          </div>
          <span>
            Total:{' '}
            <span
              style={{
                textDecoration: 'underline',
                textDecorationColor: '#ff0000'
              }}
            >
              16.3s
            </span>
          </span>
        </p>
      </GuideContainer>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '6%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Humans
      </div>
      <div
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '17%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Hardware
      </div>
      <div
        className={'fade-in-image'}
        style={{
          color: '#482e79',
          fontSize: '14px',
          position: 'absolute',
          top: '-2%',
          left: '46.5%',
          transform: 'rotate(-0.03turn)'
        }}
      >
        Web Apps
      </div>
    </div>
    <FrameParagraph marginTop={'20px'}>
      <i>However</i>, there are still a lot of other tasks that phones (or
      laptops) will be processing at this moment since they’ll have multiple
      apps running concurrently. The system tasks (that share resources with our
      app) are also going to add some significant time to the experience here
      too, say about{' '}
      <span
        style={{
          textDecoration: 'underline',
          textDecorationColor: '#fda627'
        }}
      >
        <b>1000ms</b>
      </span>
      . <u>And there you go</u> – we’ve made it just above the average range for
      the mobile load time of a single page app! It’s definitely a long shot
      from an ideal <b>3s</b>, but if we apply some front end perf
      best-practices across the areas we’ve measured – the total speed index can
      get significantly closer to that goal.
    </FrameParagraph>
  </FrameItem>
)

export default Latency31
