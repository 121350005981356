import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const TimeStamp = ({ children, date, marginTop }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <p
        style={{
          marginTop: `${marginTop ? marginTop : '20px'}`,
          fontSize: '15px'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>✏️</div>
        <i style={{ animation: 'none', color: '#747474' }}> {date} </i>
      </p>
    )}
  />
)

TimeStamp.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string.isRequired,
  marginTop: PropTypes.string
}

export default TimeStamp
