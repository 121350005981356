import React from 'react'
import FrameItem from '../../components/blog-styles/frame-item'
import FrameParagraph from '../../components/blog-styles/frame-paragraph'
import Image from '../../components/blog-styles/image'
import gif from '../../pages/blogs/assets/latency/latency11.gif'

const Latency11 = () => (
  <FrameItem>
    <Image src={gif} alt={'Server response sent'} />
    <FrameParagraph>
      Which may take more or less time depending on how many files and assets
      need to load at a given time.
    </FrameParagraph>
  </FrameItem>
)

export default Latency11
