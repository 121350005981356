import React from 'react'
import PropTypes from 'prop-types'
import Image from '../blog-styles/image'
import Anchor from '../blog-styles/anchor'
import GuideContainer from '../blog-styles/guide-container'
import FrameItem from '../blog-styles/frame-item'
import FrameParagraph from '../blog-styles/frame-paragraph'
import ImageInstruction from '../blog-styles/image-instruction'
import image from '../../pages/blogs/assets/latency/latency17.jpg'
import image2 from '../../pages/blogs/assets/latency/latency20a.jpg'
import image3 from '../../pages/blogs/assets/latency/latency20b.jpg'
import '../layout.css'

const Latency20 = () => (
  <FrameItem>
    <Image src={image} alt={'Humans'} width={'35%'} />
    <GuideContainer>
      <div
        style={{
          color: '#0287ad',
          fontSize: '35px',
          textTransform: 'none',
          margin: '15px 0 8px 0',
          lineHeight: '1.5',
          fontWeight: 'bold'
        }}
      >
        Mental Chronometry
      </div>
      <div
        style={{
          color: '#0287ad',
          fontSize: '28px',
          textTransform: 'none',
          lineHeight: '1.5'
        }}
      >
        The average human response time is
      </div>
      <Image src={image2} alt={'273ms'} width={'15%'} top={'-12vh'} />
      <Image
        src={image3}
        alt={'Mental Chronometry cycle'}
        className={'fade-in-image'}
        width={'20%'}
        marginTop={'0px'}
      />
      <ImageInstruction
        instruction={'(Source: The Humanbenchmark Study)'}
        linkURL={'https://humanbenchmark.com/tests/reactiontime'}
        marginBottom={'30px'}
      />
    </GuideContainer>
    <FrameParagraph marginTop={'0px'}>
      As we broaden our scope of what compises latency a bit, we first need to
      step back from the front end entirely and see how the latency{' '}
      <u>in our own biological system</u> works. It seems that human behavior
      (in general) is currently the most untapped variable in the web app
      latency equation. What we do know though, is that through the{' '}
      <Anchor
        ulr={'https://humanbenchmark.com/tests/reactiontime'}
        linkName={'Human Benchmark Study'}
      />{' '}
      we’ve learned that the average human response time to new stimuli is{' '}
      <b>273ms</b>.
    </FrameParagraph>
  </FrameItem>
)

Latency20.propTypes = {
  children: PropTypes.node
}

export default Latency20
